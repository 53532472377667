<!--
 * @Author: SongYijie
 * @Date: 2020-09-09 17:41:34
 * @LastEditors: SongYijie
-->

<template>
  <div class='main has-header'>
    <mt-header fixed title="签约列表">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <ul class="list">
      <li v-for="item in list" :key="item.id">
        <a :href="item.contactDetail" download="pdf">
          查看{{ item.serviceProviderName }}协议
            <i class="mint-cell-allow-right"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  //这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  import {
    getUserSigningList
  } from '@api/tasks'
  export default {
    props: ['taskId'],
    components: {},
    data() {
      return {
        list: []
      };
    },
    methods: {
      loadMore() {
        this.getData()
      },
      getData() {
        getUserSigningList(this.taskId).then((res) => {
          if (res.data == null || res.data.length == 0) {
            this.$router.push('/task/sign-success')
          }
          this.list = res.data.map((item, index) => {
            let contact = item.locatoinOss
            let url = contact == null ? '/task/sign-success' : contact.split(',')[0]
            item.contactDetail = url
            return item
          })
        })
        .catch((err) => {
        })
      }
    },
    mounted() {
      this.getData()
    }
  };
</script>

<style lang='less' scoped>
  ul.list {
    border-top: 8px solid #f5f5f5;
    li {
      font-size: 1rem;
      line-height: 1.5rem;
      a {
        text-decoration: none;
        display: block;
        padding: 8px 15px;
        border-bottom: 8px solid #f5f5f5;
        position: relative;
      }
    }
  }
</style>